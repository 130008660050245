<template>
  <div>
    <b-alert v-if="cantLoadData" variant="danger" show>
      <h4 class="alert-heading">Erreur</h4>
      <div class="alert-body">
        <span>{{ cantLoadDataText }}</span>
      </div>
    </b-alert>
    <b-card no-body>
      <!-- SINGLE DATA -->
      <b-modal
        ref="singleDataModal"
        centered
        :title="'Fichier: ' + singleData.slug"
        ok-only
        ok-title="Fermer"
      >
        <single-view :data="singleData" />
      </b-modal>
      <!-- SAVE/EDIT RECORD -->
      <b-modal
        id="setDataModal"
        ref="setDataModal"
        :title="
          Object.entries(this.modaldata.data).length === 0
            ? 'Nouveau'
            : 'Modification'
        "
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          {{
            Object.entries(this.modaldata.data).length === 0
              ? "Nouveau"
              : `Modification ${this.modaldata.data.title}`
          }}
          <hr />
          <small class="text-danger">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="save">
          <!-- CATEGORY -->
          <b-form-group label="* Module" label-for="parent">
            <v-select
              id="parent"
              v-model="formdata.parent"
              label="title"
              value="slug"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Sélectionner un élément"
              :options="buildDataParent"
            />
          </b-form-group>
          <!-- TITLE -->
          <b-form-group label="* Titre" label-for="title">
            <b-form-input
              id="title"
              v-model="formdata.title"
              placeholder="Titre"
              required
            />
          </b-form-group>
          <!-- CONTENT -->
          <b-form-group label="Description" label-for="content">
            <b-form-textarea
              id="content"
              v-model="formdata.content"
              rows="4"
              placeholder="Description"
            />
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('save')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow" />
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- IMPORT RECORD -->
      <b-modal
        id="importModal"
        ref="importModal"
        title="Importation"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Importation de liste de données
          <hr />
        </h3>

        <b-form @submit.prevent="importer">
          <!-- FILE -->
          <b-form-group label="Pièce jointe" label-for="file">
            <b-form-file
              id="file"
              v-model="formdata.file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              placeholder="Charger un fichier ou glissez-le ici..."
              drop-placeholder="Glisser un fichier ici..."
              size="lg"
              no-drop
              required
              @change="this.$coddyger.updateCurrFile"
            />
            <a
              v-if="formdata.file"
              class="text text-danger"
              @click="emptyFileInput"
              ><small>Retirer fichier</small></a
            >
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('import')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Importer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow" />
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="tabledata.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tabledata.pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button class="mr-1" variant="primary" @click="openSaveDialog">
              Nouveau
            </b-button>
            <!--<b-button variant="outline-primary" @click="openImportDialog">
              Importer
            </b-button>-->
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="tabledata.filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="buildData"
        responsive
        :fields="tabledata.columns"
        primary-key="id"
        :current-page="tabledata.currentPage"
        :per-page="tabledata.perPage"
        :filter="tabledata.filter"
        show-empty
        empty-text="Auncune donnée disponible"
        class="position-relative"
        :busy.sync="tabledata.isBusy"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner small type="grow" />
            <strong> Veuillez patienter...</strong>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`post-row-${data.item.slug}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-1"
              @click.stop="openDialog(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mr-1"
              @click.stop="buildEditModal(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class=""
              @click.stop="deleteRecordPrompt(data.item.slug)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Total: {{ buildData.length }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="tabledata.currentPage"
              :total-rows="tabledata.totalRows"
              :per-page="tabledata.perPage"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import CategoryStore from '@/store/app/category/'
import * as payloads from './payloads.json'
import SingleView from './components/single-view.vue'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BAlert,

    vSelect,
    SingleView,
  },
  data() {
    return {
      cantLoadData: false,
      cantLoadDataText: payloads.cantLoadDataText,
      payloads: {},
      isLoading: false,
      singleData: [], // For single view mode
      locale: 'fr',
      isModalActive: false,
      modalData: [],
      // TABLE PARAMS
      tabledata: {
        isBusy: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, 30],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        columns: [
          {
            key: 'index',
            label: '#',
            sortable: true,
            formatter: val => `${val}`,
          },
          {
            key: 'title',
            label: 'Titre',
            sortable: true,
            formatter: val => `${val}`,
          },
          {
            key: 'parent',
            label: 'Catégorie',
            sortable: true,
            formatter: (val) => `${val !== null ? `${val.title}` : "Catégorie Primaire"}`,
          },
          {
            key: "created_at",
            label: "Enregistré le",
            sortable: true,
            formatter: (val) => `${val !== null ? `${this.$coddyger.cdgDateFormat(val)}` : ""}`,
          },
          { key: 'actions' },
        ],
        searchTerm: '',
      },
      // Data NEW/EDIT Modal
      formdata: {
        slug: null,
        // -FORM-PROPERTIES-
        title: '',
        content: '',
        parent: '',
        file: null,
        categories: payloads.category,
        options: {
          date: {
            date: true,
            delimiter: '-',
            datePattern: ['d', 'm', 'Y'],
          },
        },
      },
      // MODAL TOOLS
      modaldata: {
        data: [],
        isModalActive: false,
      },
    }
  },
  computed: {
    buildData() {
      const { data } = this.$store.state.CategoryStore
      this.tabledata.totalRows = data === undefined ? 0 : data.length

      return data === undefined ? [] : data
    },
    buildDataParent() {
      const { dataParent } = this.$store.state.CategoryStore

      return dataParent === undefined ? [] : dataParent
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true
      }
      return false
    },
  },
  created() {
    /* ----------------------------------------
    | Build payloads on component ready!
      ---------------------------------------- */
    // Build store
    this.buildStore()
    // Build payloads
    this.buildPayloads()
    // Build store data
    this.buildStoreData()

    // Build foreigners
    this.buildForeigners()
  },

  methods: {
    /* ----------------------------------------
    | APP STORE
      ---------------------------------------- */
    buildStore() {
      if (!CategoryStore.isRegistered) {
        this.$store.registerModule('CategoryStore', CategoryStore)
        CategoryStore.isRegistered = true
      }
    },
    /* ----------------------------------------
    | APP PAYLOADS
      ---------------------------------------- */
    buildPayloads() {
      let { path } = this.$router.history.current
      const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
      path = getLastItem(path)

      this.payloads = {
        route: `apps-${path}`,
        paths: {
          select: `/api/${path}/select`,
          selectParent: `/api/${path}/select-parent`,
          save: `/api/${path}/save`,
          edit: `/api/${path}/edit`,
          remove: `/api/${path}/remove`,
          importer: `/api/${path}/import`,
        }
      }
    },
    buildStoreData() {
      this.tabledata.isBusy = true;
      this.$store.dispatch('CategoryStore/fetch', this.payloads.paths.select).then(() => {
          this.tabledata.isBusy = false;
        }).catch(() => {
          this.tabledata.isBusy = false;
          this.cantLoadData = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Erreur",
              icon: "WarningIcon",
              variant: "danger",
              text: "Impossible de charger la liste des données. Merci de réessayer plutard.",
            },
          });
        });
    },
    buildForeigners() {
      this.$store.dispatch('CategoryStore/fetchParent', this.payloads.paths.selectParent);
    },

    emptyFileInput() {
      this.formdata.file = null
    },
    closeModal(object) {
      if (object === 'save') {
        this.$refs.setDataModal.hide()
        this.resetValues()
      } else if (object === 'edit') {
        this.$refs.editModal.hide()
        this.resetValues()
      } else {
        this.$refs.importModal.hide()
      }
    },
    openSaveDialog() {
      this.$refs.setDataModal.show()
    },
    openImportDialog() {
      this.$refs.importModal.show()
    },
    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    openDialog(data) {
      this.$refs.singleDataModal.show()
      this.singleData = data
    },

    /* ---------------------
    | RECORD REMOVAL HANDLER
    */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: { ...this.$route.query, pid: id },
      })
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Voulez-vous supprimer cet enregistrement ?', {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.deleteData()
          } else {
            this.revertUrl()
          }
        })
    },
    deleteData() {
      const id = this.$route.query.pid
      if (id !== '' || id !== null) {
        this.$store
          .dispatch('CategoryStore/remove', {
            item: id,
            path: this.payloads.paths.remove,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Action Réussie',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Enregistrement supprimé avec succès!',
              },
            })
            this.revertUrl()
          })
          .catch(error => {
            this.swalError('Erreur', error.message, 'error')
          })
      }
    },
    // REVERT URL TO THE PREVIOUS
    revertUrl() {
      this.$router.replace({ name: this.payloads.route })
    },
    // MODAL FORM TOOL --------------
    resetValues() {
      this.formdata.slug = null
      this.formdata.title = ''
      this.formdata.parent = []
      this.formdata.content = ''
      this.formdata.file = null
      this.modaldata.data = {}
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      if (this.formdata.title === '') {
        this.swalError('Erreur', this.buildRequiredMessage('titre'), 'error')
      } else if (this.formdata.parent === '') {
        this.swalError(
          'Erreur',
          this.buildRequiredMessage('Catégorie'),
          'error',
        )
      } else {
        let global = {}
        const formData = {
          slug: this.formdata.slug,
          title: this.formdata.title,
          parent: this.formdata.parent.slug,
          content: this.formdata.content || '',
        }

        global = {
          formdata: formData,
          path: ((this.formdata.slug !== null && this.formdata.slug !== '') ? this.payloads.paths.edit : this.payloads.paths.save),
        }

        this.isLoading = true

        if (this.formdata.slug !== null && this.formdata.slug !== '') {
          this.$store
            .dispatch('CategoryStore/edit', global)
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Action Réussie',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Modification effectué avec succès!',
                },
              })
            })
            .catch(error => {
              this.isLoading = false
              console.log(error)

              this.swalError('Erreur', error.message, 'error')
            })
        } else {
          this.$store
            .dispatch('CategoryStore/save', global)
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Action Réussie',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Enregistrement modifié avec succès!',
                },
              })
              this.resetValues()
            })
            .catch(error => {
              this.isLoading = false
              console.log(error)

              this.swalError('Erreur', error.message, 'error')
            })
        }
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`
    },
    buildEditModal(data) {
      this.modaldata.data = data
      this.modaldata.isModalActive = true

      if (!data) return
      if (Object.entries(data).length === 0) {
        this.resetValues()
      } else {
        const {
          slug, title, parent, content,
        } = JSON.parse(
          JSON.stringify(data),
        )

        this.formdata.slug = slug
        this.formdata.title = title
        this.formdata.parent = parent
        this.formdata.content = content
      }

      this.$refs.setDataModal.show()
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app

    return {
      appName,
      appLogoImage,
      appDesc,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
