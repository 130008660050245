<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-list-group
          v-if="data"
          flush
        >
          <b-list-group-item>Titre: <b>{{ data.title }}</b></b-list-group-item>
          <b-list-group-item v-if="data.parent">Parent: <b>{{ data.parent.title }}</b></b-list-group-item>
          <b-list-group-item>Description: <b>{{ data.content }}</b></b-list-group-item>

          <b-list-group-item>Date de création:
            <b>{{
              this.$coddyger.cdgDateFormat(data.created_at, true)
            }}</b></b-list-group-item>
          <b-list-group-item>Dernière mis à jour:
            <b>{{
              this.$coddyger.cdgDateFormat(data.updated_at, true)
            }}</b></b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col lg="12">
            <b-list-group
              v-if="data.user"
              flush
            >
              <b-list-group-item>Administrateur: <b>{{ data.user.fullname }}</b></b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BTooltip,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const postStatusVariantAndIcon = requestStat => {
      if (requestStat === 'pending') return { variant: 'warning', icon: 'PieChartIcon', text: 'En attente' }
      if (requestStat === 'active') return { variant: 'success', icon: 'CheckCircleIcon', text: 'Active' }
      if (requestStat === 'archived') return { variant: 'danger', icon: 'XIcon', text: 'Désactivé' }
      if (requestStat === 'ended') return { variant: 'info', icon: 'CheckCircleIcon', text: 'Terminé' }
      return { variant: 'primary', icon: 'XIcon' }
    }

    return {
      postStatusVariantAndIcon,
    }
  },
})
</script>
